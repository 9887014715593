<template>
  <v-container fluid class="customer-care pb-15 px-0 pt-0">
    <!-- <div
      class="header-image d-flex flex-column justify-center align-center py-16 mb-8"
    >
      <h1>Servizio Clienti</h1>
      <v-divider />
    </div> -->
    <!-- <CategoryTitle :category="category" class="text-center" /> -->
    <div class="description text-center px-7 px-sm-0 py-sm-4">
      <h2 style="font-size: 28px;">CONTATTACI</h2>
      <p class="sub mt-4">
        <span>PER RICHIEDERE INFORMAZIONI O COMUNICARE CON NOI</span
        ><br /><br /><span
          >Compila i campi sottostanti, lo Staff Iperal accoglierà la tua
          richiesta e ti risponderà il prima possibile.</span
        ><br /><span style="font-size: 18px;"
          ><span class="font-weight-bold"
            >Il caricamento di eventuali allegati è possibile dopo aver
            effettuato il login.</span
          ></span
        ><br /><span class="font-weight-bold"
          ><br />Il nostro Servizio Clienti è attivo dal lunedì al sabato dalle
          9:00 alle 18:00.</span
        >
      </p>
    </div>

    <v-divider />

    <v-form v-model="valid" class="mx-5 mx-sm-auto">
      <v-row no-gutters>
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="user.CardCode"
            :label="$t('customerReportingForm.label.fidelityCard')"
            type="tel"
            :rules="fidelityCardRules"
            :readonly="fidelityCardReadOnly"
          />
        </v-col> </v-row
      ><v-row no-gutters>
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="user.Name"
            :label="`${$t('customerReportingForm.label.firstName')} *`"
            :rules="requiredRules"
            :readonly="isAuthenticated"
            required
          />
        </v-col>
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="user.Surname"
            :label="`${$t('customerReportingForm.label.lastName')} *`"
            :rules="requiredRules"
            :readonly="isAuthenticated"
            required
          />
        </v-col>
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="user.Cell"
            :label="`${$t('customerReportingForm.label.phone')}`"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="user.Email"
            :label="`${$t('customerReportingForm.label.email')} *`"
            :rules="emailRules"
            :readonly="isAuthenticated"
            required
          />
        </v-col>
        <!-- <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-select
            :items="pdvList"
            v-model="user.IdPDVPref"
            item-value="CodicePDV"
            item-text="PDV"
            :label="`${$t('customerReportingForm.label.pdv')} *`"
            :rules="requiredRules"
            required
            ><template v-slot:append>
              <v-icon color="primary">mdi-chevron-down</v-icon></template
            ></v-select
          >
        </v-col> -->
        <v-col cols="12" sm="6" class="px-0 px-sm-2">
          <v-text-field
            v-model="orderModel"
            :label="`${$t('customerReportingForm.label.order')}`"
          />
        </v-col>
        <v-col cols="12" class="px-0 px-sm-2" v-if="!isAuthenticated">
          <legend>
            {{ $t("customerReportingForm.label.messageNotAuthenticated") }}
          </legend>
        </v-col>
        <v-col
          cols="12"
          class="d-flex flex-row align-center justify-center px-0 px-sm-2"
        >
          <v-file-input
            accept="image/png, image/jpeg, image/jpg"
            :disabled="!isAuthenticated"
            ref="uploadField"
            prepend-icon="mdi-cloud-upload"
            multiple
            show-size
            :label="`${$t('customerReportingForm.label.attachment')}`"
            v-model="attachmentModel"
          ></v-file-input>
        </v-col>
        <v-col cols="12" v-if="isAuthenticated"
          ><p color="$text-color" style="font-size: 13px; opacity: 0.6;">
            Dimensione massima: 5MB <br /></p
        ></v-col>
        <v-col cols="12" class="px-0 px-sm-2">
          <v-textarea
            no-resize
            :label="`${$t('customerReportingForm.label.message')} *`"
            v-model="descriptionModel"
            :rules="requiredRules"
            required
          >
          </v-textarea>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center" align="center" class="mt-7 mb-5 small">
        <i18n path="customerReportingForm.privacyLink" tag="span">
          <template v-slot:link>
            <router-link
              v-on:click.stop.prevent="() => {}"
              to="/page/privacy-policy"
              target="_blank"
              class="black--text font-weight-bold"
              >PRIVACY</router-link
            >
          </template>
        </i18n>
      </v-row>
      <v-row no-gutters justify="center" align="center" class="small">
        <span class="mr-4">{{
          `${$t("customerReportingForm.consent")} *`
        }}</span>
        <v-checkbox
          color="primary"
          hide-details
          required
          :label="$t('common.yes')"
          :value="false"
          @change="privacyAccepted = !privacyAccepted"
        />
      </v-row>
      <v-row>
        <v-col cols="12">
          <RecaptchaDisclaimer @click="clicked" />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="my-7">
        <v-btn
          x-large
          tile
          depressed
          color="primary"
          @click="handleUserDataSubmit"
          :disabled="!valid || !privacyAccepted"
          class="mx-auto"
          :loading="loadingUpdate"
        >
          {{ $t("customerReportingForm.send") }}
        </v-btn>
      </v-row>
      <v-alert type="error" v-if="fileErrorString">{{
        $t("customerReportingForm.fileTooBigError")
      }}</v-alert>
    </v-form>
    <p class="text-center mb-5 small">
      {{ `* ${$t("customerReportingForm.mandatoryFields")}` }}
    </p>
    <v-row no-gutters justify="center" align="center">
      <span class="font-weight-bold mr-3 mb-3 mb-sm-0">
        {{ $t("customerReportingForm.contactUs") }}
      </span>
      <a href="tel:800232730">
        <v-img
          max-height="51"
          max-width="140"
          src="/img/numero-verde-iperal.svg"
          alt="Numero verde: 800232730"
        />
      </a>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.customer-care {
  .header-image {
    text-align: center;
    background: url("../../../public/img/customer_care.jpeg");
    background-size: cover;
    background-position: bottom;
    h1 {
      color: white;
      font-size: 75px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1;
      max-width: 50%;
    }
    .v-divider {
      width: 130px;
      border-width: 2px;
      margin: 10px auto 0px;
      background: var(--v-primary-base);
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h1 {
        font-size: 35px;
      }
    }
  }
  .description {
    .sub {
      font-size: 15px;
    }
  }
  .v-divider {
    width: 65px;
    border-width: 2px;
    margin: 0 auto 20px;
    background: var(--v-primary-base);
  }
  .v-form {
    max-width: 550px;
    .col {
      .v-input__slot {
        .v-label {
          color: var(--v-primary-base) !important;
        }
        input {
          font-weight: bold;
        }
      }
    }
    .v-input--selection-controls {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
  .small {
    font-size: 13px;
  }
}
</style>

<script>
import IperalCustomService from "@/service/iperalCustomService";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";
import {
  requiredValue,
  isEmail,
  isNumber,
  minLength,
  maxLength
} from "@/validator/validationRules";
import { mapGetters, mapState } from "vuex";

export default {
  name: "customerReportingForm",
  components: { RecaptchaDisclaimer },
  data() {
    return {
      user: {},
      valid: false,
      pdvList: [],
      attachmentModel: null,
      descriptionModel: null,
      orderModel: null,
      loadingUpdate: false,
      fileErrorString: false,
      requiredRules: [requiredValue()],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      fidelityCardRules: [
        isNumber("Inserire solo numeri"),
        minLength(13, "Il codice CartAmica è di 13 caratteri"),
        maxLength(13, "Il codice CartAmica è di 13 caratteri")
      ],
      fidelityCardReadOnly: false,
      readOnlyIfLogged: false,
      privacyAccepted: false
    };
  },
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    }
  },
  methods: {
    setPdvName(pdv) {
      return `${pdv.addressName} (${pdv.province})`;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      });
    },
    async fetchUserData() {
      this.user = await IperalCustomService.getUser();
      if (this.user.CardCode) {
        this.fidelityCardReadOnly = true;
      }
    },
    async handleUserDataSubmit(e) {
      try {
        var _this = this;
        e.preventDefault();
        this.loadingUpdate = true;
        let totalFilesSize = 0;
        if (this.attachmentModel) {
          totalFilesSize = this.attachmentModel.reduce(
            (partialSum, file) => partialSum + file.size,
            0
          );
        }
        if (this.attachmentModel && totalFilesSize / 1024 > 5120) {
          this.fileErrorString = true;
        } else {
          this.fileErrorString = false;

          var paramUser = {};
          var regMod = {};
          let fidelityCardCode = {};
          let regStatus = {};
          let regDisclaimerList = [];
          let regDisclaimer = {};
          let regModuleAction = {};
          let infos = [];
          fidelityCardCode.code = this.user.CardCode;
          regMod.userId = this.cart.user?.userId;
          regMod.firstName = this.user.Name;
          regMod.lastName = this.user.Surname;
          regMod.mobilePhone = this.user.Cell;
          regMod.email = this.user.Email;
          regMod.fidelityCard = fidelityCardCode;
          // eslint-disable-next-line no-debugger
          // debugger;
          regMod.warehouseId = this.user.IdPDVPref
            ? parseInt(this.user.IdPDVPref)
            : null;
          regMod.personId = this.cart.user?.person.personId;
          regStatus.registrationModuleStatusId = 1;
          regMod.registrationModuleStatus = regStatus;
          regDisclaimer.value = "true";
          regDisclaimer.registrationDisclaimer = {};
          regDisclaimer.registrationDisclaimer.registrationDisclaimerId = 1;
          regModuleAction.registrationModuleActionId = 998;
          regModuleAction.registrationModuleInfoTypeId = 999;
          regModuleAction.name = "Form Segnalazioni";
          regModuleAction.active = 1;
          regMod.registrationModuleAction = regModuleAction;
          let moduleType = {};

          paramUser.userId = this.cart.user?.userId;
          paramUser.firstName = this.user.Name;
          paramUser.lastName = this.user.Surname;
          paramUser.mobilePhone = this.user.Cell;
          paramUser.email = this.user.Email;
          paramUser.fidelityCard = this.user.CardCode;
          paramUser.warehouseId = this.user.IdPDVPref
            ? parseInt(this.user.IdPDVPref)
            : null;
          paramUser.login = this.user.Email;
          moduleType.registrationModuleTypeId = 999;
          regDisclaimerList.push(regDisclaimer);
          regMod.registrationModuleType = moduleType;
          regMod.registrationModuleDisclaimers = regDisclaimerList;
          paramUser.registrationModule = regMod;
          paramUser.personId = this.cart.user?.person.personId;

          if (this.attachmentModel) {
            let documents = [];

            for (let i = 0; i < _this.attachmentModel.length; i++) {
              let document = {};
              let file = _this.attachmentModel[i];
              document.contentText = await this.toBase64(file);

              document.fileName = file.name;
              document.mimeType = file.type;
              documents.push(document);
            }
            paramUser.registrationModule.documents = documents;
          }
          if (this.descriptionModel) {
            let note = {};
            note.value = this.descriptionModel;
            note.registrationModuleInfoType = {};
            note.active = 1;
            note.registrationModuleInfoType.registrationModuleInfoTypeId = 999;
            infos.push(note);
            paramUser.registrationModule.infos = infos;
          }

          if (this.orderModel) {
            let order = {};
            order.value = this.orderModel;
            order.registrationModuleInfoType = {};
            (order.active = 1),
              (order.registrationModuleInfoType.registrationModuleInfoTypeId = 997);
            infos.push(order);
            paramUser.registrationModule.infos = infos;
          }

          let registrationModuleDisclaimers = [];
          let disclaimer = {};
          disclaimer.value = registrationModuleDisclaimers.push(
            this.user.ConsentFacilitatedDelivery
          );

          let res = await IperalCustomService.sendReportingForm(paramUser);
          if (res && res.status == 0) {
            global.EventBus.$emit("success", {
              message: global.EventBus.$t("customerReportingForm.submitSuccess")
            });
            // this.$router.push("/");
            this.$router.push({
              name: "CustomerReportingCompleted"
            });
          }
          // }
        }
      } catch (r) {
        console.log(r);
        this.response = r;
      } finally {
        this.loadingUpdate = false;
      }
    }
  },
  async mounted() {
    this.pdvList = await IperalCustomService.getPDVList();
    var generic = {
      CodicePDV: "000",
      PDV: "Generico"
    };
    this.pdvList.unshift(generic);
    if (this.isAuthenticated) {
      await this.fetchUserData();
    }
  }
};
</script>
